import React, {useRef} from 'react'
import {CSSTransition} from 'react-transition-group'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const TRANSITION_DELAY = 100;

const TransitionTarget = styled.div`
    width: 100%;
    height: 100%;
    &.fade-in-appear {
        transform: translateY(-20px);
        opacity: 0;
    }
    &.fade-in-appear-active {
        transform: translateY(0);
        opacity: 1;
        transition: all 200ms ease-in-out ${props => props.order * TRANSITION_DELAY}ms;
    }
    &.fade-in-appear-done {
        opacity: 1;
        transform: translateY(0);
    }
`

const FadeIn = ({children, order=0, wrapper, props}) => {
    const nodeRef = useRef(null)
    const Wrapper = wrapper;
    return (
        <CSSTransition
            appear={true}
            in={true}
            timeout={200 + (order * TRANSITION_DELAY)}
            classNames="fade-in"
            nodeRef={nodeRef}
        >
            <Wrapper {...props}>
                <TransitionTarget
                    order={order}
                    ref={nodeRef}
                >
                    {children}
                </TransitionTarget>
            </Wrapper>
        </CSSTransition>
    )
}

FadeIn.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]).isRequired,
    order: PropTypes.number,
    wrapper: PropTypes.elementType.isRequired
}

export default FadeIn