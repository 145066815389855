import React from 'react';
import styled from 'styled-components';
import AreaSelect from './AreaSelect/AreaSelect';
import EnvironmentSelect from './EnvironmentSelect/EnvironmentSelect';
import FadeInGroup from '../../shared/FadeInGroup/FadeInGroup';
import Link from '../../shared/Link/Link'
import ActionBar from '../../shared/ActionBar/ActionBar'
import { connect } from 'react-redux'

const ActionBarWrapper = styled.div`
    margin-top: 5px;
`

const AreaSelectWrapper = styled.div`
    height: 80px;
`

const EnvironmentSelectWrapper = styled.div`
    flex: 1;
    overflow: hidden;
`

const Wrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`
const ImageSelect = ({continueAllowed}) => {
    return (
        <Wrapper>
            <FadeInGroup components={[
                {
                    wrapper: AreaSelectWrapper,
                    content: <AreaSelect />
                },
                {
                    wrapper: EnvironmentSelectWrapper,
                    content: <EnvironmentSelect />
                },
                {
                    wrapper: ActionBarWrapper,
                    content: (
                        <ActionBar>
                            <Link to='/'>← Zurück</Link>
                            <Link to='/edit' disabled={!continueAllowed} primary>Weiter</Link>
                        </ActionBar>)
                }
            ]}
            />
        </Wrapper>
    )
}

const mapStateToProps = ({selectedImageId, selectedAreaId}) => ({
    continueAllowed: selectedImageId && selectedAreaId
})

export default connect(mapStateToProps)(ImageSelect)