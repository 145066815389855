import { SET_HOVERED_COLOR_ID, UNSET_HOVERED_COLOR_ID } from "../actions/shared";

const hoveredColorId = (state = null, action) => {
    const {type, payload} = action;
    switch (type) {
        case SET_HOVERED_COLOR_ID:
            return payload.id;
        case UNSET_HOVERED_COLOR_ID:
            return null;
        default:
            return state;
    }
};

export default hoveredColorId;