import { LOAD_CONFIG, SET_QUERY_STATE } from "../actions/shared";
import { 
    SET_SELECTED_MASK_ID, 
    SET_HOVERED_MASK_ID, 
    SET_MASK_COLOR_ID, 
    UNSET_HOVERED_MASK_ID,
    RESET_CONFIG,
    UNSET_SELECTED_MASK_ID} from "../actions/images"

const images = (state = null, action) => {
    const {type, payload} = action;
    switch (type) {
        case LOAD_CONFIG:
            return {
                ...state,
                ...payload.images
            }
        case SET_SELECTED_MASK_ID:
            return {
                ...state,
                [payload.selectedImageId]: {
                    ...state[payload.selectedImageId],
                    selectedMaskId: payload.id
                }
            }
        case UNSET_SELECTED_MASK_ID:
            return {
                ...state,
                [payload.selectedImageId]: {
                    ...state[payload.selectedImageId],
                    selectedMaskId: null
                } 
            }
        case SET_MASK_COLOR_ID:
            return {
                 ...state,
                 [payload.selectedImageId]: {
                     ...state[payload.selectedImageId],
                     masks: {
                         ...state[payload.selectedImageId].masks,
                         [payload.selectedMaskId]: {
                             ...state[payload.selectedImageId].masks[payload.selectedMaskId],
                             colorId: payload.id
                         }
                     }
                 }
            }
        case SET_HOVERED_MASK_ID:
            return {
                ...state,
                [payload.selectedImageId]: {
                    ...state[payload.selectedImageId],
                    hoveredMaskId: payload.id
                }
            }
        case UNSET_HOVERED_MASK_ID:
            return {
                ...state,
                [payload.selectedImageId]: {
                    ...state[payload.selectedImageId],
                    hoveredMaskId: null
                }
            }
        case SET_QUERY_STATE: 
            return {
                ...state,
                [payload.selectedImageId]: {
                    ...state[payload.selectedImageId],
                    masks: Object.keys(payload.maskColorIds).reduce(
                        (acc, key) => {
                            return Object.assign(acc, {[key]: {
                                ...state[payload.selectedImageId].masks[key],
                                colorId: payload.maskColorIds[key]
                            }})
                        }, {...state[payload.selectedImageId].masks}
                    )
                }
            }
        case RESET_CONFIG:
            return {
                ...state,
                [payload.selectedImageId]: {
                    ...state[payload.selectedImageId],
                    masks: Object.entries(state[payload.selectedImageId].masks).reduce(
                            (acc, [key, value]) => {
                                return Object.assign(acc,
                                    {
                                        [key]: {
                                            ...value,
                                            colorId: 0
                                        }
                                    })
                            }, {}
                        )
                }
            }
        default:
            return state;
    }
};

export default images;