import PropTypes from 'prop-types'
import React from 'react'
import FadeIn from './FadeIn/FadeIn'

const FadeInGroup = ({ components }) => {
    return (
        <>
            {components.map((component, index) => {
                const Wrapper = component.wrapper;
                const { content } = component;
                return (
                    <FadeIn 
                        key={index}
                        order={index} 
                        wrapper={Wrapper}
                    >
                        {content}
                    </FadeIn>
                )
            })}
        </>
    )
}

FadeInGroup.propTypes = {
    components: PropTypes.arrayOf(PropTypes.shape({
        content: PropTypes.element.isRequired,
        wrapper: PropTypes.elementType.isRequired
    })).isRequired
}

export default FadeInGroup