import { combineReducers } from "redux";

import images from './images'
import colors from './colors'
import selectedImageId from './selectedImageId'
import isLoading from './isLoading'
import hoveredColorId from './hoveredColorId'
import selectedAreaId from './selectedAreaId'
import areas from './areas'
import sidebar from './sidebar'

export default combineReducers({
    images,
    colors,
    selectedImageId,
    isLoading,
    hoveredColorId,
    selectedAreaId,
    areas,
    sidebar
})