import React from 'react'
import { handleLoadConfig } from '../actions/shared'
import {connect} from 'react-redux'
import Routes from './Routes/Routes'
import styled from 'styled-components'
import Header from './shared/Header/Header'
import Tooltip from './shared/Tooltip/Tooltip'
import Sidebar from './shared/Sidebar/Sidebar'
import { useMediaQuery } from 'react-responsive'

const Wrapper = styled.div`
    height: calc(100% - 20px);
    display: flex;
    flex-direction: column;
    margin: 10px 0;
    @media (min-aspect-ratio: 16/10) {
        margin: 50px;
        height: calc(100% - 100px);
    }
`

const App = ({isLoading, loadConfig, hoveredColorName}) => {
    React.useEffect(() => {loadConfig()}, [loadConfig])
    const canHover = useMediaQuery({
        query: '(hover: hover)'
    })
    return !isLoading && (
        <Wrapper>
            <Header />
            <Routes />
            {hoveredColorName && canHover && <Tooltip text={hoveredColorName}/>}
            <Sidebar />
        </Wrapper>
    )
}

const mapStateToProps = ({ isLoading, hoveredColorId, colors }) => ({
    isLoading,
    hoveredColorName: !isLoading && colors[hoveredColorId]?.name
})

const mapDispatchToProps = dispatch => ({
    loadConfig: () => dispatch(handleLoadConfig())
})

export default connect(mapStateToProps, mapDispatchToProps)(App)