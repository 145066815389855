import React from 'react'
import styled from 'styled-components'
import {connect} from 'react-redux'
import { handleSetMaskColorId } from '../../../../actions/images'
import {setHoveredColorId, unsetHoveredColorId} from '../../../../actions/shared'

const ColorScrollView = styled.div`
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    overflow-y: scroll;
    align-content: flex-start;
    justify-content: center;
`

const Color = styled.div`
    cursor: pointer;
    transition: transform 100ms ease-out;
    &:hover {
        transform: scale(1.2) rotate(3deg);
    }
    background-color: ${props => props.bgc};
    height: 30px;
    width: 30px;
    margin: 10px;
    outline: ${props => props.selected && '3px solid #bbb'};
`

const Wrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`

const ColorSelect = ({colors, selectColor, selectedColorId, setHoveredColor, unsetHoveredColor, selectedMaskName}) => {
    const roofColors = {"0": colors[0], "16": colors[16]}
    return (
        <Wrapper>
            {selectedMaskName !== "Dach" ? 
                <ColorScrollView>
                    {Object.keys(colors).map(key => (
                        <Color 
                            key={key}
                            bgc={colors[key].value} 
                            onClick={() => selectedColorId !== undefined && selectColor(key)}
                            onMouseEnter={() => setHoveredColor(key)}
                            onMouseLeave={() => unsetHoveredColor()}
                            selected={key === selectedColorId}
                        />
                    ))}
                </ColorScrollView>
            :   <ColorScrollView>
                    {Object.keys(roofColors).map(key => (
                        <Color 
                            key={key}
                            bgc={colors[key].value} 
                            onClick={() => selectedColorId !== undefined && selectColor(key)}
                            onMouseEnter={() => setHoveredColor(key)}
                            onMouseLeave={() => unsetHoveredColor()}
                            selected={key === selectedColorId}
                        />
                    ))}         
                </ColorScrollView>
            }
        </Wrapper>
    )
}

const mapStateToProps = ({colors, images, selectedImageId}) => {
    const {masks, selectedMaskId} = images[selectedImageId]
    const selectedColorId = masks[selectedMaskId]?.colorId
    return {
        selectedColorId,
        colors,
        selectedMaskName: masks[selectedMaskId]?.name
    }
}

const mapDispatchToProps = dispatch => ({
    selectColor: id => dispatch(handleSetMaskColorId(id)),
    setHoveredColor: id => dispatch(setHoveredColorId(id)),
    unsetHoveredColor: () => dispatch(unsetHoveredColorId())
})

export default connect(mapStateToProps, mapDispatchToProps)(ColorSelect)
