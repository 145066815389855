import React, {useEffect} from 'react'
import styled from 'styled-components'
import Hint from '../../../shared/Hint/Hint'
import Button from '../../../shared/Button/Button'
import { connect } from 'react-redux'
import { handleSetSidebarContent } from '../../../../actions/sidebar'

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
    height: 100%;
`
const Header = styled.div`
    height: 30px;
    border-bottom: black 1px solid;
`

const Input = styled.input`
    width: 100%;
    height: 32px;
    padding-left: 10px;
    margin-bottom: 10px;
`

const Main = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
`

const ShareSidebarContent = ({url, setSidebarContent}) => {
    useEffect(() => {
        if (navigator.share) {
            navigator.share({
                title: document.title,
                text: 'Schau dir diese Farben an!',
                url
            }).then(() => {
                setSidebarContent('selection');
            }).catch(console.error);
        }
    }, [url, setSidebarContent])
    const LinkInputRef = React.useRef(null)
    return (
        <Wrapper>
            <Header>
                <Hint>Teilen</Hint>
            </Header>
            <Main>
                <Input
                    readOnly
                    value={url}
                    ref={LinkInputRef}
                />
                <Button
                    center
                    primary
                    onClick={() => {
                        LinkInputRef.current.select()
                        LinkInputRef.current.setSelectionRange(0, 99999);
                        document.execCommand("copy")
                    }}
                >
                    Link kopieren
                </Button>
            </Main>
                <Button right back onClick={() => setSidebarContent('selection')}>Schliessen</Button>
        </Wrapper>
    )
}

const mapStateToProps = ({ selectedImageId, images }) => {
    const { masks } = images[selectedImageId]
    const searchParams = new URLSearchParams()
    searchParams.set('imgId', selectedImageId)
    Object.keys(masks).forEach(key => {
        searchParams.set(key, masks[key].colorId)
    })
    return {
        url: `${window.location}?${searchParams.toString()}`
    }
};

const mapDispatchToProps = dispatch => ({
    setSidebarContent: content => dispatch(handleSetSidebarContent(content))
});

export default connect(mapStateToProps, mapDispatchToProps)(ShareSidebarContent);