export const SHOW_SIDEBAR = 'SHOW_SIDEBAR'
export const SET_SIDEBAR_CONTENT = 'SET_SIDEBAR_CONTENT'

export const showSidebar = show => ({
    type: SHOW_SIDEBAR,
    payload: {
        show
    }
})

const setSidebarContent = content => ({
    type: SET_SIDEBAR_CONTENT,
    payload: {
        content
    }
})

export const handleSetSidebarContent = content => {
    return dispatch => {
        dispatch(showSidebar(false))
        dispatch(setSidebarContent(content))
        dispatch(showSidebar(true))
    }
}