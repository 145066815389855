import styled from 'styled-components'
import {Link} from 'react-router-dom'
import ButtonStyle from '../Button/ButtonStyle'

export default styled((
    {
        large, 
        disabled, 
        primary, 
        center, 
        letsgo,
        ...rest
    }) => <Link {...rest}/>)`
    ${ButtonStyle}
    text-decoration: none;
    border: none;
    border: 1px solid black;
`