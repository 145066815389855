import React from 'react'
import styled from 'styled-components'
import ElementSelect from './ElementSelect/ElementSelect'
import ColorSelect from './ColorSelect/ColorSelect'
import Image from './Image/Image'
import FadeInGroup from '../../shared/FadeInGroup/FadeInGroup'
import Button from '../../shared/Button/Button'
import Link from '../../shared/Link/Link'
import ActionBar from '../../shared/ActionBar/ActionBar'
import { connect } from 'react-redux'
import {handleSetSidebarContent} from '../../../actions/sidebar'

/*const ActionBarWrapper = styled.div`
    margin-top: 5px;
`*/

const ImageWrapper = styled.div`
    max-width: 100%;
    flex: 1;
    background-color: rgb(243, 243, 243);
    @media (min-aspect-ratio: 2/3) {
        height: 55%;
    }
    @media (min-aspect-ratio: 16/10) {
        height: 100%;
        flex: 3;
    }
`

const ElementSelectWrapper = styled.div`
    height: 80px;
    @media (min-aspect-ratio: 1/1) {
        position: absolute;
        top: 0;
        left: 0;
        width: 120px;
        min-height: 100%;
    }
`

const ColorSelectWrapper = styled.div`
    flex: 1;
    @media (min-aspect-ratio: 1/1) and (max-aspect-ratio: 16/10) {
        max-height: 200px;
    }
    @media (min-aspect-ratio: 16/10) {
        max-width: 300px;
    }
    overflow: hidden;
`

const Wrapper = styled.div`
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    @media (min-aspect-ratio: 1/1) {
        padding-left: 150px;
    }
    @media (min-aspect-ratio: 16/10) {
        flex-direction: row;
    }
`

const ImageEdit = ({setSidebarContent}) => {
    return (
        <>
            <Wrapper>
                <FadeInGroup components={[
                    {
                        wrapper: ImageWrapper,
                        content: <Image />
                    },
                    { 
                        wrapper: ElementSelectWrapper,
                        content: <ElementSelect />
                    },
                    {
                        wrapper: ColorSelectWrapper,
                        content: <ColorSelect />
                    }
                ]}/>
            </Wrapper>
            <ActionBar>
                <Link to='/umgebung-wählen'>← Zurück</Link>
                <Button
                    primary
                    onClick={() => setSidebarContent('selection')}
                >
                    Auswahl ansehen
                </Button>
            </ActionBar>
        </>
    )
}

const mapDispatchToProps = dispatch => ({
    setSidebarContent: content => dispatch(handleSetSidebarContent(content))
})

export default connect(null, mapDispatchToProps)(ImageEdit);
