export const SET_SELECTED_IMAGE_ID = 'SET_SELECTED_IMAGE_ID'
export const SET_SELECTED_MASK_ID = 'SET_SELECTED_MASK_ID'
export const SET_MASK_COLOR_ID = 'SET_MASK_COLOR_ID' 
export const SET_HOVERED_MASK_ID = 'SET_HOVERED_MASK_ID'
export const UNSET_HOVERED_MASK_ID = 'UNSET_HOVERED_MASK_ID'
export const UNSET_SELECTED_MASK_ID = 'UNSET_SELECTED_MASK_ID'
export const RESET_CONFIG = 'RESET_CONFIG'

export const setSelectedImageId = (id) => ({
    type: SET_SELECTED_IMAGE_ID,
    payload: {
        id
    }
})

const setSelectedMaskId = (id, selectedImageId) => ({
    type: SET_SELECTED_MASK_ID,
    payload: {
        selectedImageId,
        id
    }
})

export const handleSetSelectedMaskId = (id) => {
    return (dispatch, getState) => {
        const {selectedImageId} = getState()
        dispatch(setSelectedMaskId(id, selectedImageId))
    }
}

const unsetSelectedMaskId = selectedImageId => ({
    type: UNSET_SELECTED_MASK_ID,
    payload: {
        selectedImageId
    }
})

export const handleUnsetSelectedMaskId = () => {
    return (dispatch, getState) => {
        const {selectedImageId} = getState()
        dispatch(unsetSelectedMaskId(selectedImageId))
    }
}

const setMaskColorId = (id, selectedMaskId, selectedImageId) => ({
    type: SET_MASK_COLOR_ID,
    payload: {
        selectedMaskId,
        selectedImageId,
        id
    }
})

export const handleSetMaskColorId = id => {
    return (dispatch, getState) => {
        const {images, selectedImageId} = getState()
        const selectedMaskId = images[selectedImageId].selectedMaskId
        dispatch(setMaskColorId(id, selectedMaskId, selectedImageId))
    }
}

export const setHoveredMaskId = (id, selectedImageId) => ({
   type: SET_HOVERED_MASK_ID,
   payload: {
       id,
       selectedImageId
   }
})

const unsetHoveredMaskId = selectedImageId => ({
    type: UNSET_HOVERED_MASK_ID,
    payload: {
        selectedImageId
    }
 })

 export const handleUnsetHoveredMaskId = () => {
    return (dispatch, getState) => {
        const {selectedImageId} = getState()
        dispatch(unsetHoveredMaskId(selectedImageId))
    }
}

export const handleSetHoveredMaskId = id => {
    return (dispatch, getState) => {
        const {selectedImageId} = getState()
        dispatch(setHoveredMaskId(id, selectedImageId))
    }
}

const resetConfig = selectedImageId => ({
    type: RESET_CONFIG,
    payload: {
        selectedImageId
    }
})

export const handleResetConfig = () => {
    return (dispatch, getState) => {
        const {
            selectedImageId
        } = getState()
        dispatch(resetConfig(selectedImageId))
    }
}