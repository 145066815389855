import React from 'react'
import { connect } from 'react-redux'
import {useMediaQuery} from 'react-responsive'

export const Masks = ({ masks, colors, src, dimensions }) => {
    const isLargeDevice = useMediaQuery({query: '(min-width: 1024px)'})
    return (
        <g>
            {Object.keys(masks).map(id => {
                return (
                    <g key={id}>
                        <defs>
                            <clipPath id={`${id}-path`}>
                                <path d={masks[id].d} />
                            </clipPath>
                            <filter
                                key={id}
                                id={`${id}-filter`}
                            >
                                <feFlood
                                    className="feFlood"
                                    floodColor={colors[masks[id].colorId].value}
                                    result="flood"
                                    style={{transition: 'flood-color 0.3s ease-in'}}
                                />
                                <feComposite
                                    in="SourceGraphic"
                                    in2="flood"
                                    operator="arithmetic"
                                    k1="1"
                                    k2="0"
                                    k3="0"
                                    k4="0"
                                />
                            </filter>
                        </defs>
                        <image
                            width={dimensions.width}
                            height={dimensions.height}
                            href={`/images/umgebungen/${isLargeDevice ? 'm_' : ''}${src}`}
                            filter={`url(#${id}-filter)`}
                            clipPath={`url(#${id}-path)`}
                        />
                    </g>)
            })}
        </g>
    )
}

const mapStateToProps = ({ images, selectedImageId, colors }) => {
    const { masks, src, dimensions} = images[selectedImageId]
    return {
        masks,
        selectedImageId,
        colors,
        src,
        dimensions
    }
}

export default connect(mapStateToProps, null)(Masks)
