import React, {useRef} from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import SelectionSidebarContent from '../../Routes/ImageEdit/SelectionSidebarContent/SelectionSidebarContent'
import ShareSidebarContent from '../../Routes/ImageEdit/ShareSidebarContent/ShareSidebarContent'
import {handleSetSidebarContent, showSidebar} from '../../../actions/sidebar'
import {CSSTransition} from 'react-transition-group'

const Wrapper = styled.div`
    z-index: 1000;
    background-color: white;
    box-shadow: 0 -3px 3px black;
    padding: 10px;
    position: fixed;
    height: 60%;
    width: 100%;
    left: 0;
    bottom: 0;
    border-radius: 10px 10px 0 0;
    transform: translateY(100%);
    &.slide-in-enter {
        transform: translateY(100%);
    }
    &.slide-in-enter-active {
        transform: translateY(0);
        transition: transform 200ms ease-out;
    }
    &.slide-in-enter-done {
        transform:translateY(0);
    }
    &.slide-in-exit {
        transform: translateY(0);
    }
    &.slide-in-exit-active {
        transform: translateY(100%);
        transition: transform 200ms ease-out;
    }
    &.slide-in-exit-done {
        transform: translateY(100%);
    }
    @media (min-aspect-ratio: 2/3) {
        height: 100%;
        width: 400px;
        left: auto;
        right: 0;
        border-radius: 0px 0 0 0;
        transform: translateX(100%);
        &.slide-in-enter {
            transform: translateX(100%);
        }
        &.slide-in-enter-active {
            transform: translateX(0);
            transition: transform 200ms ease-out;
        }
        &.slide-in-enter-done {
            transform:translateX(0);
        }
        &.slide-in-exit {
            transform: translateX(0);
        }
        &.slide-in-exit-active {
            transform: translateX(100%);
            transition: transform 200ms ease-out;
        }
        &.slide-in-exit-done {
            transform: translateX(100%);
        }
    }
`

const SidebarOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    height: 100%;
    width: 100%;
    background-color: black;
    opacity: 0.4;    
`

const renderContent = content => {
    switch (content) {
        case 'share':
            return <ShareSidebarContent />;
        case 'selection':
            return <SelectionSidebarContent />;
        default:
            return null;
    };
};

const Sidebar = ({ show, showSidebar, content }) => {
    const nodeRef = useRef(null)
    return (
        <>
            {show && <SidebarOverlay onClick={() => showSidebar(false)} />}
            <CSSTransition
                appear
                in={show}
                timeout={200}
                classNames="slide-in"
                nodeRef={nodeRef}
                unmountOnExit
            >
                <Wrapper ref={nodeRef}>
                    {show && renderContent(content)}
                </Wrapper>
            </CSSTransition>
        </>
    )
}

const mapStateToProps = ({sidebar}) => ({
    show: sidebar.show,
    content: sidebar.content
});

const mapDispatchToProps = dispatch => ({
    showSidebar: show => dispatch(showSidebar(show)),
    setSidebarContent: content => dispatch(handleSetSidebarContent(content))
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);