import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import {Provider} from 'react-redux'
import { createStore } from 'redux';
import rootReducer from './reducers';
import middleware from './middleware';
import { composeWithDevTools } from 'redux-devtools-extension';
import { BrowserRouter as Router } from 'react-router-dom'
import { createGlobalStyle } from 'styled-components'

const store = createStore(rootReducer, composeWithDevTools(middleware));

const GlobalStyle = createGlobalStyle`
  * {
      margin: 0;
      padding: 0;
      font-family: 'Raleway';
      box-sizing: border-box;
  }
  html, body {
    height: 100%;
    overflow: hidden;
  }
  #root {
    height: 100%;
  }
`


ReactDOM.render(
  <React.StrictMode>
    <Router>
      <GlobalStyle />
      <Provider store={store}>
        <App />
      </Provider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);