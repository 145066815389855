import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import {handleResetConfig} from '../../../../actions/images'
import ActionBar from '../../../shared/ActionBar/ActionBar'
import Button from '../../../shared/Button/Button'
import Hint from '../../../shared/Hint/Hint'
import { handleSetSidebarContent, showSidebar } from '../../../../actions/sidebar'

const Header = styled.div`
    height: 30px;
    @media (min-aspect-ratio: 1/1) {
        border-bottom: rgb(192, 192, 192) 1px solid;
    }
`

const Selection = styled.div`
    display: flex;
    height: 24px;
    margin: 10px 0;
`

const AreaName = styled.span`
    text-transform: capitalize;
    font-size: 16px;    
    line-height: 24px;
    margin-right: 10px;
    margin-left: 2%;
    width: 40%;
    overflow: hidden;
    text-overflow: ellipsis;
`

const ColorName = styled.div`
    font-size: 16px;
    line-height: 24px;
    width: calc(60% - 10px);
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: ${props => props.bgc};
    text-align: center;
    color: ${props => getTextColor(props.bgc)};
`

const Wrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
`
const SelectionWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden;
    background-color: #eee;
`

const SelectionScrollView = styled.div`
    padding: 5px;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
`


const getTextColor = (hex) => {
    var rgbColor = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    const isDark = parseInt(rgbColor[1], 16)*0.299 + parseInt(rgbColor[2], 16)*0.587 + parseInt(rgbColor[3], 16)*0.114 > 186
    return (isDark ? '#00000' : '#ffffff')
}

const SelectionSidebarContent = ({areas, colors, showSidebar, setSidebarContent, resetConfig}) => {
    return (
        <>
            <Wrapper>
                <Header>
                    <Hint>Auswahl</Hint>
                </Header>
                <SelectionWrapper>
                    <SelectionScrollView>
                        {Object.keys(areas).map(key => (
                            <Selection key={key}>
                                <AreaName>{areas[key].name}</AreaName>
                                <ColorName
                                    bgc={colors[areas[key].colorId].value}
                                >
                                    {colors[areas[key].colorId].name}
                                </ColorName>
                            </Selection>
                        ))}
                    </SelectionScrollView>
                    <Button 
                        warning 
                        left
                        fullWidth
                        onClick={
                        () => {
                            resetConfig()
                            showSidebar(false)
                        }}
                    >
                        Zurücksetzen
                    </Button>
                </SelectionWrapper>
                <ActionBar>
                <Button 
                    primary 
                    onClick={() => setSidebarContent('share')}
                >
                    Teilen
                </Button>
                <Button onClick={() => showSidebar(false)}>Schliessen</Button>
            </ActionBar>
            </Wrapper>
        </>
    )
}

const mapStateToProps = ({ selectedImageId, images, colors }) => {
    const { masks } = images[selectedImageId]
    return {
        areas: masks,
        colors
    }
}

const mapDispatchToProps = dispatch => ({
    resetConfig: () => dispatch(handleResetConfig()),
    setSidebarContent: content => dispatch(handleSetSidebarContent(content)),
    showSidebar: show => dispatch(showSidebar(show))
})

export default connect(mapStateToProps, mapDispatchToProps)(SelectionSidebarContent)