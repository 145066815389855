import React from 'react'
import styled, {keyframes} from 'styled-components'

const SvgAnimation = keyframes`
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg)
  }
`

const CircleAnimation = keyframes`
    0%,
    25% {
        stroke-dashoffset: 280;
        transform: rotate(0);
    }
    
    50%,
    75% {
        stroke-dashoffset: 75;
        transform: rotate(45deg);
    }
    
    100% {
        stroke-dashoffset: 280;
        transform: rotate(360deg);
    }
`

const Svg = styled.svg`
  animation: 2s linear infinite ${SvgAnimation};
  width: 50px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
  circle {
    animation: 1.4s ease-in-out infinite both ${CircleAnimation};
    display: block;
    fill: transparent;
    stroke: #2f3d4c;
    stroke-linecap: round;
    stroke-dasharray: 283;
    stroke-dashoffset: 280;
    stroke-width: 10px;
    transform-origin: 50% 50%;
}
`

export default function LoadingSpinner() {
    return (
        <Svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <circle cx="50" cy="50" r="30"/>
        </Svg>
    )
}
