import React from 'react'
import styled from 'styled-components'

const Text = styled.p`
    padding: 4px 0;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 1px;
    margin-bottom: 20px;
    font-weight: 600;
    color: darkblue;
`

const Subtext = styled.p`
    padding: 4px 0;
    font-size: 14px;
    line-height: 22px;
    color: rgb(141, 141, 141);
    margin-bottom: 2vw;
`


export default function LandingText() {
    return (
        <>
            <Text>
                Mit dem Farbkonfigurator können Sie schon vor dem Kauf ausprobieren, 
                ob die gewünschte Farbe zu Ihrem Projekt passt
            </Text>
            <Subtext>
                Achtung! Farbtöne auf dem Monitor entsprechen nicht immer dem tatsächlichen Farbton. Diese 
                Animation dient als Orientierungshilfe, um Farbkombinationen am Monitor auszuprobieren. 
                Im Zweifel sollte immer ein Farbton anhand einer Farbmusterkarte herangezogen werden.
            </Subtext>
        </>
    )
}
