import {SHOW_SIDEBAR, SET_SIDEBAR_CONTENT} from '../actions/sidebar'

const initialState = {
    show: false,
    content: 'selection'
};

const sidebar = (state=initialState, action) => {
    const {type, payload} = action;
    switch (type) {
        case SHOW_SIDEBAR: 
            return {
                ...state,
                show: payload.show
            };
        case SET_SIDEBAR_CONTENT:
            return {
                ...state,
                content: payload.content
            };
        default:
            return state;
    };
};

export default sidebar;