import React from 'react'
import styled from 'styled-components'
import {connect} from 'react-redux'
import Masks from './Masks/Masks'
import LoadingSpinner from '../../../shared/LoadingSpinner/LoadingSpinner'
import {useMediaQuery} from 'react-responsive'

const Svg = styled.svg`
    height: 100%;
    width: 100%;
    object-fit: contain;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
`

const Wrapper = styled.div`
    height: 100%;
    width: 100%;
    position: relative;
`

const Image = ({ src, dimensions, selectedImageId }) => {
    const imageRef = React.useRef(null)
    const [imageHasLoaded, setImageHasLoaded] = React.useState(false)
    const isLargeDevice = useMediaQuery({query: '(min-width: 1024px)'})
    return (
        <Wrapper>
            {!imageHasLoaded && <LoadingSpinner/>}
            <Svg
                style={{opacity: !imageHasLoaded && '0'}}
                ref={imageRef}
                className="image"
                key={selectedImageId}
                xmlns="http://www.w3.org/2000/svg" version="1.1"
                viewBox={`0 0 ${dimensions.width} ${dimensions.height}`}
            >
                    <image
                        onLoad={() => setImageHasLoaded(true)}
                        href={`/images/umgebungen/${isLargeDevice ? 'm_' : ''}${src}`}
                        width={dimensions.width}
                        height={dimensions.height}
                    />
                <Masks />
            </Svg>
        </Wrapper>
    )
}

const mapStateToProps = ({ selectedImageId, images }) => {
    const { src, dimensions } = images[selectedImageId]
    return {
        src,
        dimensions,
        selectedImageId
    }
}

export default connect(mapStateToProps)(Image)