import { SET_IS_LOADING } from "../actions/shared";

const isLoading = (state = true, action) => {
    const {type, payload} = action;
    switch (type) {
        case SET_IS_LOADING:
            return payload.isLoading
        default:
            return state;
    }
};

export default isLoading;