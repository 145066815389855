import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px;
    order: 3;
`

export default function ActionBar({children}) {
    return (
        <Wrapper>
            {children}
        </Wrapper>
    )
}
