export const LOAD_CONFIG = 'LOAD_CONFIG'
export const SET_IS_LOADING = 'IS_LOADING'
export const SET_HOVERED_COLOR_ID = 'SET_HOVERED_COLOR_ID'
export const UNSET_HOVERED_COLOR_ID = 'UNSET_HOVERED_COLOR_ID'
export const SET_SELECTED_AREA_ID = 'SET_SELECTED_AREA_ID'
export const SET_QUERY_STATE = 'SET_QUERY_STATE'

export const loadConfig = (config) => {
    return {
        type: LOAD_CONFIG,
        payload: {
            ...config,
        },
    };
}

export const setIsLoading = isLoading => {
    return {
        type: SET_IS_LOADING,
        payload: {
            isLoading
        }
    }
}

export const setHoveredColorId = (id) => ({
    type: SET_HOVERED_COLOR_ID,
    payload: {
        id
    }
})

export const unsetHoveredColorId = (id) => ({
    type: UNSET_HOVERED_COLOR_ID
})

export const handleLoadConfig = () => {
    return dispatch => {
        dispatch(setIsLoading(true))
        fetch('/api/config.json')
            .then(res => res.json())
            .then(config => {
                dispatch(loadConfig({...config}))
                dispatch(setIsLoading(false))
            })
    }
}

export const setSelectedAreaId = selectedAreaId => ({
    type: SET_SELECTED_AREA_ID,
    payload: {
        selectedAreaId
    }
})

const setQueryState = payload => ({
    type: SET_QUERY_STATE,
    payload
})

export const handleSetQueryState = query => {
    return (dispatch, getState) => {
        const selectedImageId = query.get("imgId")
        const {images, colors} = getState()
        const selectedImage = images[selectedImageId]
        query.delete("imgId")
        if (selectedImage) {
            const payload = {selectedImageId, maskColorIds: {}}
            for (let [key, value] of query.entries()) {
                if (selectedImage.masks[key] && colors[value]) {
                    payload.maskColorIds[key] = value
                }
            }
            dispatch(setQueryState(payload))
        }
    }
}