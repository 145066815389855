import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import Environment from './Environment/Environment'
import FadeInGroup from '../../../shared/FadeInGroup/FadeInGroup'
import Hint from '../../../shared/Hint/Hint'

const EnvironmentScrollView = styled.div`
    background-color: #eee;
    padding: 5px;
    height: calc(100% - 24px);
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    align-content: flex-start;
    flex-wrap: wrap;
`

const EnvironmentWrapper = styled.div`
    height: 180px;
    margin: 10px;
`

const EnvironmentSelect = ({ environments, selectedAreaId }) => {
    return (
        <>
            <Hint>Wählen Sie eine Umgebung aus.</Hint>
            <EnvironmentScrollView>
                    <FadeInGroup 
                        components={
                            Object.keys(environments).map(
                                key => ({
                                    wrapper: EnvironmentWrapper,
                                    content: <Environment id={key}/>
                                })
                            )}
                    />
            </EnvironmentScrollView>
        </>
    )
}

const mapStateToProps = ({ images, selectedAreaId }) => {
    return {
        environments: Object.keys(images)
            .reduce((acc, key) => {
                return (images[key].areaId === selectedAreaId
                    ? Object.assign(acc, { [key]: images[key] })
                    : acc)
            }, {}),
        selectedAreaId
    }
}

export default connect(mapStateToProps, null)(EnvironmentSelect)