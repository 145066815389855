import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
    min-height: 48px;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    margin: 1%;
`

const Logo = styled.a`
    background: url(${props => props.src});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 140px;
    height: 100%;
`

const Title = styled.h1`
    font-size: 24px;
    line-height: 48px;
`

const Header = () => {
    return (
        <Wrapper>
            <Title>Farbkonfigurator</Title>
            <Logo 
                src="https://www.schwedischer-farbenhandel.de/wp-content/uploads/2015/03/Logo.jpg"
                href={'https://www.schwedischer-farbenhandel.de/'}
            />
        </Wrapper>
    )
}

export default Header