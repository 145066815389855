import {
    SET_SELECTED_AREA_ID
} from '../actions/shared'

const selectedAreaId = (state = null, action) => {
    const {
        type,
        payload
    } = action;
    switch (type) {
        case SET_SELECTED_AREA_ID:
            return payload.selectedAreaId
        default:
            return state;
    }
};

export default selectedAreaId;