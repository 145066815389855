import {
    LOAD_CONFIG,
    SET_QUERY_STATE
} from "../actions/shared";
import { SET_SELECTED_IMAGE_ID } from "../actions/images"

const selectedImageId = (state = null, action) => {
    const {type, payload} = action;
    switch (type) {
        case LOAD_CONFIG:
            return payload.selectedImageId
        case SET_SELECTED_IMAGE_ID:
            return payload.id
        case SET_QUERY_STATE:
            return payload.selectedImageId
        default:
            return state;
    }
};

export default selectedImageId;