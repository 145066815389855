import {useEffect, useState} from 'react'
import styled from 'styled-components'

const MouseTrackingSpan = styled.span.attrs(({top, right}) => ({
    style: {
        top: top + 'px',
        right: right + 'px'
    }
}))`
    position: fixed;
    background-color: #333;
    color: white;
    padding: 0.5em 1em;
    border-radius: 5px;
    text-transform: capitalize;
`

const setupMouseMoveListener = setPosition => {
    const handleMouseMove = e => {
        const x = e.clientX,
              y = e.clientY;
        setPosition({
            top: (y + 20),
            right: (window.innerWidth - x )
        })
    }
    window.addEventListener("mousemove", handleMouseMove)
    return (() => {window.removeEventListener("mousemove", handleMouseMove)})
}

const Tooltip = ({text}) => {
    const [position, setPosition] = useState(null)
    useEffect(() => setupMouseMoveListener(setPosition), [])
    return position && (
        <MouseTrackingSpan top={position.top} right={position.right}>
            {text}
        </MouseTrackingSpan>)
}

export default Tooltip;