import React from 'react'
import styled from 'styled-components'
import LandingText from './LandingText/LandingText'
import Link from '../../shared/Link/Link'
import LandingImage from './LandingImage/LandingImage'

const Wrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    justify-content: center;
    align-content: center;
    @media (min-aspect-ratio: 1/1) {
        flex-direction: row;
    }
    overflow: hidden;
`

const LandingTextWrapper = styled.div`
    margin: 20px;
    @media (min-aspect-ratio: 1/1) {
       max-width: 400px;
       width: 50%;
    } 
    
`

export default function Home () {
    return (
        <Wrapper>
            <LandingImage />
            <LandingTextWrapper>
                <LandingText />
                <Link primary center letsgo large to='/umgebung-wählen'>Loslegen 🠒</Link>
            </LandingTextWrapper>
        </Wrapper>
    )
}
