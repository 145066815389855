import {
    LOAD_CONFIG
} from "../actions/shared";

const colors = (state = null, action) => {
    const {
        type,
        payload
    } = action;
    switch (type) {
        case LOAD_CONFIG:
            return {
                ...state,
                ...payload.areas
            }
            default:
                return state;
    }
};

export default colors;