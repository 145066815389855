import React from 'react'
import styled from 'styled-components'
import {connect} from 'react-redux'
import {setSelectedAreaId} from '../../../../actions/shared'
import Hint from '../../../shared/Hint/Hint'

const Wrapper = styled.div`
`

const AreaScrollView = styled.div`
    height: calc(80px - 24px);
    padding: 5px;
    display: flex;
    justify-content: space-around;
    @media (min-width: 1024px) {
        justify-content: flex-start;
    }
`

const Area = styled.div`
    &:hover {
        background-color: #eee;
    }
    cursor: pointer;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 10px;
    outline: ${props => props.selected && '2px solid #ccc'};
    padding: 0 10px;
`

const AreaName = styled.span`
    font-size: 12px;
    line-height: 24px;
`

const AreaIcon = styled.img`
    height: calc(80px - 24px - 24px - 10px);
    width: calc(80px - 24px - 24px - 10px);
    object-fit: contain;
`

const AreaSelect = ({selectArea, selectedAreaId, areas}) =>  {
    React.useEffect(() => {
        !selectedAreaId && selectArea('0')
    }, [selectedAreaId, selectArea])
    return (
            <Wrapper>
                <Hint>Wählen Sie einen Bereich aus.</Hint>
                <AreaScrollView>
                    {Object.keys(areas).map(key => (
                        <Area 
                            key={key}
                            selected={key === selectedAreaId}
                            onClick={() => selectArea(key)}
                        >
                            <AreaIcon src={areas[key].icon}/>
                            <AreaName>{areas[key].name}</AreaName>
                        </Area>
                    ))}
                </AreaScrollView>
            </Wrapper>
    )
}

const mapStateToProps = ({selectedAreaId, areas}) => {
    return {
        selectedAreaId,
        areas
    }
}

const mapDispatchToProps = dispatch => ({
    selectArea: id => dispatch(setSelectedAreaId(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(AreaSelect)