import styled from 'styled-components'
import React from 'react'
import { setSelectedImageId } from '../../../../../actions/images'
import {connect} from 'react-redux'
import LoadingSpinner from '../../../../shared/LoadingSpinner/LoadingSpinner'

const Wrapper = styled.div`
    &:hover > img {
        transform: scale(1.1);
    }
    cursor: pointer;
    border: 5px solid #eee;
    height: calc(200px - 24px);
    width: 310px;
    display: flex;
    flex-direction: column;
    position: relative;
    outline: ${props => props.selected && '2px solid #aaa'};
    overflow: hidden;
`

const EnvironmentName = styled.span`
    z-index: 10;
    background-color: #eee;
    line-height: 24px;
    font-size: 16px;
    text-align: center;
`

const EnvironmentImage = styled.img`
    transition: transform 300ms ease-out;
    width: 100%;
    height: calc(100% - 24px);
    object-fit: cover;
    background-color: #bbb;
`

const Environment = ({title, src, id, selectEnvironment, selectedEnvironmentId}) => {
    const [imageHasLoaded, setImageHasLoaded] = React.useState(false)
    return (
            <Wrapper
                onClick={() => selectEnvironment(id)}
                selected={id === selectedEnvironmentId}
            >
                {!imageHasLoaded && <LoadingSpinner/>}
                <EnvironmentImage
                    style={{opacity: !imageHasLoaded && '0'}}
                    onLoad={() => setImageHasLoaded(true)}
                    src={`/images/umgebungen/${src}`}
                />
                <EnvironmentName>{title}</EnvironmentName>
            </Wrapper>
    )
}

const mapStateToProps = ({selectedImageId, images}, {id}) => {
    const {src, title} = images[id]
    return {
        id,
        src,
        title,
        selectedEnvironmentId: selectedImageId
    }
}

const mapDispatchToProps = dispatch => ({
    selectEnvironment: id => dispatch(setSelectedImageId(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(Environment)