import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import {Switch, Route, Redirect, useLocation} from 'react-router-dom'
import { handleSetQueryState} from '../../actions/shared'
import ImageEdit from './ImageEdit/ImageEdit'
import ImageSelect from './ImageSelect/ImageSelect'
import Home from './Home/Home'

export const Routes = ({environmentHasBeenSelected, setQueryState}) => {
    const { search } = useLocation()
    useEffect(() => {
        search && setQueryState(new URLSearchParams(search))
    }, [search, setQueryState])
    return (
        <Switch>
            <Route exact path="/umgebung-wählen">
                <ImageSelect />
            </Route>
            <Route exact path="/edit">
                {
                    () => {
                        if (search) {
                            return <Redirect to="/edit" />
                        } else if (environmentHasBeenSelected) {
                            return <ImageEdit />
                        } else {
                            return <Redirect to="/home" />
                        }
                    }
                }
            </Route>
            <Route path="/" component={Home} />
        </Switch>
    )
}

const mapStateToProps = ({selectedImageId}) => ({
    environmentHasBeenSelected: selectedImageId
})

const mapDispatchToProps = dispatch => ({
    setQueryState: query => dispatch(handleSetQueryState(query))
})

export default connect(mapStateToProps, mapDispatchToProps)(Routes)
