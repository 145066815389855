import React from 'react'
import styled from 'styled-components'

import LoadingSpinner from '../../../shared/LoadingSpinner/LoadingSpinner'

const Image = styled.img`
    object-fit: contain;
    flex-shrink: 1;
    max-width: 100%;
    min-height: 0;
    border-radius: 10px;
    @media (min-aspect-radio: 1/1) {
        max-width: auto;
        flex: 1;
    }
    @media (max-height: 700px) {
        height: 100px;
    }
    @media (max-height: 520px) {
        display: none;
    }
`

const Wrapper = styled.div`
    position: relative;
    max-width: 500px;
    margin-right: 0px;
    @media (min-aspect-ratio: 1/1) {
        margin-right: 50px;
    }
`

export default function LandingImage () {
    const [imageHasLoaded, setImageHasLoaded] = React.useState(false)
    return (
        <Wrapper>
            {!imageHasLoaded && <LoadingSpinner/>}
            <Image
                style={{opacity: !imageHasLoaded && '0'}}
                onLoad={() => setImageHasLoaded(true)}
                src={`/images/hauswand_farben_wechsel.gif`} 
            />
        </Wrapper>
    )
}
