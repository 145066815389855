import {css} from 'styled-components'

const ButtonStyle = css`
    padding: 0 10px;
    line-height: 32px;
    align-self: flex-start;
    justify-self: flex-start;
    border-radius: 7px;
    letter-spacing: 2px;
    cursor: pointer;
    transition: transform 0.1s ease-out;
    transform: scale(1);
    &:hover {
        transform: scale(1.05);
    }
    ${props => props.center && css`
        align-self: center;
        justify-self: center;
    `}
    ${props => props.right && css`
        align-self: flex-end;
        justify-self: flex-end;
    `}
    ${props => props.primary && css`
        background-color: blue;
        color: white;
    `}
    ${props => props.warning && css`
        background-color: white;
        color: red;
        border: red 1px solid;
    `}
    ${props => props.large && css`
        padding: 9px 30px;
    `}
    ${props => props.disabled && css`
        cursor: not-allowed;
        opacity: 0.2;
        pointer-events: none;
    `}
    /*${props => props.letsgo && css`
        border-radius: 10px;
        margin-right: -30px;
        min-width: 0px;
        &:after {
            position: relative;
            content: '🠒';
            transition: opacity 0.2s, width 0.2s;
            opacity: 0;
            margin-left: 10px;
            font-size: 30px;
            font-weight: bold;
            vertical-align: middle;
        }
        &:hover {
        }
        &:hover:after {
            opacity: 1;
        }

    `}*/
    ${props => props.fullWidth && css`
        width: calc(100% - 20px);
        margin: 10px;
    `}
`

export default ButtonStyle;