import React from 'react'
import styled from 'styled-components'
import {connect} from 'react-redux'
import {handleSetSelectedMaskId} from '../../../../actions/images'

const ElementScrollView = styled.div`
    padding: 5px;
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
    justify-items: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    width: 100%;
    height: 100%;
    border-top: rgb(223, 223, 223) solid 1px;
    @media (min-aspect-ratio: 1/1) {
        padding-right: 20px;
        border-top: none;
        flex-direction: column;
        overflow-x: hidden;
        overflow-y: scroll;
    }
`

const Element = styled.div`
    &:hover {
        background-color: #eee;
    }
    cursor: pointer;
    padding: 5px 10px;
    height: 70px;
    width: 100%;
    margin: 0 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: ${props => props.selected && '2px solid #bbb'};
`

const ElementName = styled.span`
    text-align: center;
    font-size: 14px;
`

const ElementIcon = styled.div`
    height: calc(80px - 24px - 24px - 10px);
    width: calc(80px - 24px - 24px - 10px);
    background-image: url(${props => props.src});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
`

const Wrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    @media (min-aspect-ratio: 1/1) {
        width: 170px;
    }
`

const ElementSelect = ({elements, selectedElementId, selectElement}) => {
    React.useEffect(() => {
        !selectedElementId && selectElement('0')
            }, [selectedElementId, selectElement])
    return (
            <Wrapper>
                <ElementScrollView
                >
                    {Object.keys(elements).map(key => (
                        <Element 
                            key={key}
                            onClick={() => selectElement(key)}
                            selected={key === selectedElementId}
                        >
                            <ElementIcon 
                                src={elements[key].icon}
                            />
                            <ElementName>{elements[key].name}</ElementName>
                        </Element>
                    ))}
                </ElementScrollView>
            </Wrapper>
    )
}

const mapStateToProps = ({images, selectedImageId}) => {
    const {masks, selectedMaskId} = images[selectedImageId]
    return {
        elements: masks,
        selectedElementId: selectedMaskId
    }
}

const mapDispatchToProps = dispatch => ({
    selectElement: id => dispatch(handleSetSelectedMaskId(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(ElementSelect)

